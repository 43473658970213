<template>
    <div class="bar-container">
        <dv-border-box-8>
            <div :id="id" style="height:400px;width:100%"></div>
        </dv-border-box-8>
    </div>
</template>
<script>
import * as echarts from 'echarts'
export default {
    props:{
        id:{
            type:String,
            default:'myCharts'
        },
        title:{
            type:String,
            default:''
        },
        yData:{
            type:Array,
            default:[]
        }
    },
    data(){
        return {
            
        }
    },
    mounted(){
        var mCharts = echarts.init(document.getElementById(this.id))
      
     // 指定图表的配置项和数据
        var option = {
        	//--------------    标题 title  ----------------   
            title: {					         	
                text:this.title,
                x:"1%",
                y:'4%',
                textStyle:{
                    fontSize:15,
                    color:'#06f1ef'
                }
            },
           
            tooltip: {
            	show:true,					//---是否显示提示框,默认为true
            	trigger:'item',				//---数据项图形触发
            	axisPointer:{				//---指示样式
            		type:'shadow',		
            		axis:'auto',	

            	},
            	padding:5,
            	textStyle:{					//---提示框内容样式
            		color:"#fff",			
            	},
            },

            //-------------  grid区域  ----------------
            grid:{
            	show:false,					//---是否显示直角坐标系网格
            	top:80,						//---相对位置，top\bottom\left\right  
            	containLabel:false,			//---grid 区域是否包含坐标轴的刻度标签
            	tooltip:{					//---鼠标焦点放在图形上，产生的提示框
            		show:true,	
            		trigger:'item',			//---触发类型
            		textStyle:{
            			color:'#666',
            		},
            	}
            },

            //-------------   x轴   -------------------
            xAxis: {
            	show:true,					//---是否显示
            	position:'bottom',			//---x轴位置
            	offset:0,					//---x轴相对于默认位置的偏移
            	type:'category',			//---轴类型，默认'category'
            	name:'文档名称',				//---轴名称
            	nameLocation:'end',			//---轴名称相对位置
            	nameTextStyle:{				//---坐标轴名称样式
            		color:"#fff",
            		padding:[5,0,0,-5],	//---坐标轴名称相对位置
            	},
            	nameGap:15,					//---坐标轴名称与轴线之间的距离
            	//nameRotate:270,			//---坐标轴名字旋转
            	
            	axisLine:{					//---坐标轴 轴线
            		show:true,					//---是否显示
            		
            		//------------------- 箭头 -------------------------
            		symbol:['none', 'arrow'],	//---是否显示轴线箭头
            		symbolSize:[8, 8] ,			//---箭头大小
            		symbolOffset:[0,7],			//---箭头位置
            		
            		//------------------- 线 -------------------------
            		lineStyle:{
            			color:'#fff',
            			width:1,
            			type:'solid',
            		},
            	},
            	axisTick:{					//---坐标轴 刻度
            		show:true,					//---是否显示
            		inside:true,				//---是否朝内
            		lengt:3,		//---长度
            		lineStyle:{
            			//color:'red',			//---默认取轴线的颜色
            			width:1,
            			type:'solid',
            		},
            	},
            	axisLabel:{					//---坐标轴 标签
            		show:true,					//---是否显示
            		inside:false,				//---是否朝内
            		rotate:20,					//---旋转角度	
            		margin: 5,					//---刻度标签与轴线之间的距离
            		//color:'red',				//---默认取轴线的颜色
            	},
            	splitLine:{					//---grid 区域中的分隔线
            		show:false,					//---是否显示，'category'类目轴不显示，此时我的X轴为类目轴，splitLine属性是无意义的
            		lineStyle:{
            			//color:'red',
            			//width:1,
            			//type:'solid',
            		},
            	},
            	splitArea:{					//--网格区域
            		show:false,					//---是否显示，默认false
            	},           	
                data: ["我的测试文档5","服务平台与健康系统集成","友虹平台使用手册","新建 DOC 文档","计算机软件著作权登记申请表","我的测试文档"],//内容
            },

            //----------------------  y轴  ------------------------
            yAxis: {
            	show:true,					//---是否显示
            	position:'left',			//---y轴位置
            	offset:0,					//---y轴相对于默认位置的偏移
            	type:'value',			//---轴类型，默认'category'
            	name:'文件数',				//---轴名称
            	nameLocation:'end',			//---轴名称相对位置value
            	nameTextStyle:{				//---坐标轴名称样式
            		color:"#fff",
            		padding:[5,0,0,5],	//---坐标轴名称相对位置
            	},
            	nameGap:15,					//---坐标轴名称与轴线之间的距离
            	//nameRotate:270,			//---坐标轴名字旋转
            	
            	axisLine:{					//---坐标轴 轴线
            		show:true,					//---是否显示
            		
            		//------------------- 箭头 -------------------------
            		symbol:['none', 'arrow'],	//---是否显示轴线箭头
            		symbolSize:[8, 8] ,			//---箭头大小
            		symbolOffset:[0,7],			//---箭头位置
            		
            		//------------------- 线 -------------------------
            		lineStyle:{
            			color:'#fff',
            			width:1,
            			type:'solid',
            		},
            	},
            	axisTick:{					//---坐标轴 刻度
            		show:true,					//---是否显示
            		inside:true,				//---是否朝内
            		lengt:3,					//---长度
            		lineStyle:{
            			//color:'red',			//---默认取轴线的颜色
            			width:1,
            			type:'solid',
            		},
            	},
            	axisLabel:{					//---坐标轴 标签
            		show:true,					//---是否显示
            		inside:false,				//---是否朝内
            		rotate:0,					//---旋转角度	
            		margin: 8,					//---刻度标签与轴线之间的距离
            		//color:'red',				//---默认取轴线的颜色
            	},
            	splitLine:{					//---grid 区域中的分隔线
            		show:true,					//---是否显示，'category'类目轴不显示，此时我的y轴为类目轴，splitLine属性是有意义的
            		lineStyle:{
            			color:'#666',
            			width:1,
            			type:'dashed',			//---类型
            		},
            	},
            	splitArea:{					//--网格区域
            		show:false,					//---是否显示，默认false
            	}                        
            },

            //------------ 内容数据  -----------------
            series: [
	            {
	                name: '',				//---系列名称
	                type: 'bar',				//---类型
	                legendHoverLink:true,		//---是否启用图例 hover 时的联动高亮
	                label:{						//---图形上的文本标签
	                	show:false,
	                	position:'insideTop',	//---相对位置
	                	rotate:0,				//---旋转角度
	                	color:'#eee',
	                },
	                itemStyle:{					//---图形形状
	                	color:'#01c871',
	                	barBorderRadius:[18,18,0,0],
	                },
	                barWidth:'20',				//---柱形宽度
	                barCategoryGap:'20%',		//---柱形间距
	                data: this.yData
	            }
            ]
        };
        mCharts.setOption(option)
    }
}
</script>

<style lang="less" scoped>
.bar-container{
	position: relative;
	.searchBox{
		position: absolute;
		right: 20px;
		top: 25px;
		color: rgba(255, 255, 255, 0.4);
		span{
			margin-right: 10px;
			border: 1px solid rgba(255, 255, 255, 0.4);
			padding: 3px 5px;
			font-size: 12px;
		}
	}
}
	
</style>