<template>
	<div id="main">
		<dv-loading v-if="false">Loading...</dv-loading>
		<StaticHeader></StaticHeader>
		<div class="statistic-content">
			<div class="statisticOneContent">
				<div>
					<dv-border-box-1>
						<!-- 雷达图 -->
						<RadarChart id='myRadarChart' title="文档情况统计"></RadarChart>
					</dv-border-box-1>
				</div>
				<div style="margin:0 10px" class="OneCenterContent">
					<div class="OneCenterContent-item">
						<dv-border-box-13>
							<p>共享数</p>
							<p>
								<span>
									<count-to
									:start-val="0"
									:end-val="37383"
									:duration="5000"
									class="fontG"
								/></span>
							</p>
							<dv-decoration-6 style="width:50%;height:30px;margin:10px 25% 0 25%" />
						</dv-border-box-13>
					</div>
					<div class="OneCenterContent-item">
						<dv-border-box-13>
							<p>文档数</p>
							<p>
								<span>
									<count-to
									:start-val="0"
									:end-val="78383"
									:duration="5000"
									class="fontG"
								/></span>
							</p>
							<dv-decoration-6 style="width:50%;height:30px;margin:10px 25% 0 25%" />
						</dv-border-box-13>
					</div>
					<div class="OneCenterContent-item">
						<dv-border-box-13>
							<p>用户数</p>
							<p>
								<span>
									<count-to
									:start-val="0"
									:end-val="378"
									:duration="5000"
									class="fontG"
								/></span>
							</p>
							<dv-decoration-6 style="width:50%;height:30px;margin:10px 25% 0 25%" />
						</dv-border-box-13>
					</div>
					<div class="OneCenterContent-item">
						<dv-border-box-13>
							<p>授权数</p>
							<p>
								<span>
									<count-to
									:start-val="0"
									:end-val="588"
									:duration="5000"
									class="fontG"
								/></span>
							</p>
							<dv-decoration-6 style="width:50%;height:30px;margin:10px 25% 0 25%" />
						</dv-border-box-13>
					</div>
				</div>
							<!--  -->
				<div>
					<BarChartVue id='myCharts1' title="文件共享次数排行" :yData="[120, 132, 101, 134, 90, 230, 210,120,20,42,29,33]"></BarChartVue>
					
					<!-- <BarChartVue id='myCharts2' title="用户共享次数排行" :yData="[20, 48, 36, 50, 20, 62,50,72,45,67,80,50]"></BarChartVue> -->
				</div>
			</div>
			<div class="statisticTwoContent">
				<div>
					<dv-border-box-1>
						<LineAndpieChart></LineAndpieChart>
					</dv-border-box-1>
				</div>
				<div style="margin-left:5px;">
					<ScatterChart id='myCharts4' title="文件下载排行"></ScatterChart>
				</div>
			</div>
			<div class="statisticThreeContent">
				<div>
					<dv-border-box-1>
						<div style="padding:0 20px">
							<barChart1></barChart1>
						</div>
					</dv-border-box-1>
				</div>
				<div style="margin-left:5px;">
					<LineChart id='myCharts5' title="用户下载排行" :yData="[320, 239, 132, 342, 390, 230]" :xData="['国士之风','天蓝国风','admin','测试账号','薇薇薇','tianlan']"></LineChart>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import BarChartVue from './warningCompontents/barChart.vue';
import StaticHeader from "./conpontents/staticHeader.vue";
import LineChart from './warningCompontents/lineChart.vue';
import ScatterChart from './warningCompontents/scatterChart.vue';
import RadarChart from './warningCompontents/radarChart.vue';
import LineAndpieChart from './warningCompontents/lineAndpieChart.vue';
import CountTo from "vue-count-to";
import barChart1 from './warningCompontents/barChart1.vue';
export default {
  name: "Home",
  components: {
	LineChart,
	BarChartVue,
	ScatterChart,
	StaticHeader,
	RadarChart,
	LineAndpieChart,
	CountTo,
	barChart1
  },
  data() {
    return {
		config:{
				data: [
    {
      name: '天蓝国风',
      value: 55123
    },
    {
      name: '国士之风',
      value: 12022
    },
    {
      name: 'tianlan',
      value: 78932
    },
    {
      name: '薇薇薇',
      value: 63411
    },
	 {
      name: '测试账号1',
      value: 40341
    }, {
      name: '测试账号2',
      value: 33411
    }
  ],
		}
	
    };
  },
  created() {},
  mounted() {
  },
  methods: {
  },
};
</script>

<style scoped lang="less">
#main {
  width: 100%;
  height: 1350px;
  min-width: 1850px;
  background: url("../../assets/pageBg.png") no-repeat;
  background-size: 100% 100%;
  overflow: hidden;
  ::v-deep .loading-tip {
    font-size: 23px !important;
    color: aqua;
    font-weight: bold;
  }
  .statistic-content {
    width: 100%;
    height: calc(1850px - 80px);
    padding-top: 20px;
	.statisticOneContent{
   		padding: 10px;
		clear: both;
		display: grid;
		height: 410px;
		grid-template-columns: 4fr 5fr 4fr;
		.OneCenterContent{
			height: 200px;
			display: grid;
			grid-template-columns: 8fr 8fr;
			/deep/.border-box-content{
				text-align: center;
				height: 200px;
				color:#06f1ef;
				p{
					margin: 0;
				}
				p:nth-child(1){
					font-size: 20px;
					padding-top: 50px;
					margin-bottom: 10px;
				}
				p:nth-child(2){
					font-size: 30px;
				}
			}
			
		}
	}
	.statisticTwoContent{
		margin-bottom: 10px;
		padding: 10px;
		clear: both;
		display: grid;
		height: 410px;
		grid-template-columns: 8fr 8fr;

	}
	.statisticThreeContent{
		margin-bottom: 10px;
		padding: 10px;
		clear: both;
		display: grid;
		height: 410px;
		grid-template-columns: 8fr 8fr;

	}
    
  }
}
</style>
