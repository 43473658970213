<template>
    <div class="bar-container">
            <div id="myBarCharts" style="height:380px;width:100%"></div>
    </div>
</template>
<script>
import * as echarts from 'echarts'
export default {
    data(){
        return {
            
        }
    },
    mounted(){
        var mCharts = echarts.init(document.getElementById('myBarCharts'))
      var salvProName =["天蓝国风","admin","国士之风","tianlan","薇薇薇","测试账号","管理员账号"];
var salvProValue =[239,181,154,144,135,115,33];
var salvProMax =[];//背景按最大值
for (let i = 0; i < salvProValue.length; i++) {
    salvProMax.push(salvProValue[0])
}
var option = {
	 title: {					         	
                text:'收到共享最多的用户排名',
                x:"1%",
                y:'5%',
                textStyle:{
                    fontSize:15,
                    color:'#06f1ef'
                }
            },
    grid: {
        left: '2%',
        right: '2%',
        bottom: '2%',
        top: '14%',
        containLabel: true
    },
    tooltip: {
        trigger: 'axis',
        axisPointer: {
            type: 'none'
        },
        formatter: function(params) {
            return params[0].name  + ' : ' + params[0].value
        }
    },
    xAxis: {
        show: false,
        type: 'value'
    },
    yAxis: [{
        type: 'category',
        inverse: true,
        axisLabel: {
            show: true,
            textStyle: {
                color: '#fff'
            },
        },
        splitLine: {
            show: false
        },
        axisTick: {
            show: false
        },
        axisLine: {
            show: false
        },
        data: salvProName
    }, {
        type: 'category',
        inverse: true,
        axisTick: 'none',
        axisLine: 'none',
        show: true,
        axisLabel: {
            textStyle: {
                color: '#ffffff',
                fontSize: '12'
            },
        },
        data:salvProValue
    }],
    series: [{
            name: '值',
            type: 'bar',
            zlevel: 1,
            itemStyle: {
                normal: {
                    barBorderRadius: 30,
                    color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [{
                        offset: 0,
                        color: 'rgb(57,89,255,1)'
                    }, {
                        offset: 1,
                        color: 'rgb(46,200,207,1)'
                    }]),
                },
            },
            barWidth: 20,
            data: salvProValue
        },
        {
            name: '背景',
            type: 'bar',
            barWidth: 20,
            barGap: '-100%',
            data: salvProMax,
            itemStyle: {
                normal: {
                    color: 'rgba(24,31,68,1)',
                    barBorderRadius: 30,
                }
            },
        },
    ]
};
     
        mCharts.setOption(option)
    }
}
</script>

<style lang="less" scoped>
.bar-container{
	position: relative;
	.searchBox{
		position: absolute;
		right: 20px;
		top: 25px;
		color: rgba(255, 255, 255, 0.4);
		span{
			margin-right: 10px;
			border: 1px solid rgba(255, 255, 255, 0.4);
			padding: 3px 5px;
			font-size: 12px;
		}
	}
}
	
</style>