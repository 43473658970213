<template>
    <div class="line-container">
        <dv-border-box-1>
            <!-- <div class="searchBox">
                <span>今天</span>
                <span>昨天</span>
                <span>本周</span>
                <span>本月</span>
                <span>本年</span>
                <span>全部</span>
            </div> -->
            <dv-decoration-3 style="width:200px;height:30px;position:absolute;left:150px;top:10px" />
            <div :id="id" style="height:400px;width:100%"></div>
        </dv-border-box-1>
    </div>
</template>
<script>
import * as echarts from 'echarts'
export default {
    props:{
        id:{
            type:String,
            default:'myCharts'
        },
        title:{
            type:String,
            default:''
        },
        yData:{
            type:Array,
            default:[]
        },
        xData:{
            type:Array,
            default:[]
        }
    },
    data(){
        return {
            
        }
    },
    mounted(){
        var mCharts = echarts.init(document.getElementById(this.id))
      
        var option = {
             title:{
                text:this.title,
                x:"4%",
                y:'4%',
                textStyle:{
                    fontSize:15,
                    color:'#06f1ef'
                }
            },
            grid: {
                left: "2%",
                right: "2%",
                bottom: "10%",
                containLabel: true
            },
            toolbox: {
                feature: {
                saveAsImage: {}
                }
            },
            xAxis: {
                type: 'category',
                data: this.xData,
                axisLine: {
                    lineStyle: {
                        color: "#06f1ef",
                    },
                },
            },
            yAxis: {
                type: 'value',
                scale: true,
                 axisLine: {
                    lineStyle: {
                        color: "#06f1ef",
                    },
                },
            },
            series: [
                {
                    type: 'line',
                    data: this.yData
                }
            ]
        }
        mCharts.setOption(option)
    }
}
</script>
<style scoped lang="less" >
.line-container{
    position: relative;
	.searchBox{
		position: absolute;
		right: 20px;
		top: 25px;
		color: rgba(255, 255, 255, 0.4);
		span{
			margin-right: 10px;
			border: 1px solid rgba(255, 255, 255, 0.4);
			padding: 3px 5px;
			font-size: 12px;
		}
	}
}
</style>

